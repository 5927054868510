/* eslint-disable comma-dangle */
/* eslint-disable semi */
// ** Navigation imports
import client from "./client";
import leads from "./leads";
import service from "./service";
import task from "./task";
import sales from "./sales";
import passwords from "./passwords";
import digital_signature from "./digital-signature";
import attendance from "./attendance";
import filemanager from "./filemanager";

// ** Merge & Export
export default [...leads, ...client, ...service, ...sales, ...task, ...digital_signature, ...passwords, ...attendance, ...filemanager]
